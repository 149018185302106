<template>
  <vp-base-layout
    page-title="수거 최종 확인"
    page-default-back-link="/">
    <ion-list lines="full">
      <ion-list-header>
        <ion-label>입력하신 정보가 맞는지 마지막으로 확인해주세요.</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label>• 신청자 :</ion-label>
        <ion-input
          v-model="data.order_nm"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 전화번호 :</ion-label>
        <ion-input
          v-model="data.order_tel_no"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 은행 :</ion-label>
        <ion-input
          v-model="data.bank_nm"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 예금주 :</ion-label>
        <ion-input
          v-model="data.acc_nm"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 계좌번호 :</ion-label>
        <ion-input
          v-model="decryptAcc"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 기기정보 :</ion-label>
        <ion-input
          v-model="device"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 검수등급 :</ion-label>
        <ion-input
          v-model="data.grade1"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 검수등급 상세:</ion-label>
        <ion-input
          v-model="data.grade1Detail"
          readonly="true" /> 
      </ion-item>
      <ion-item>
        <ion-label>• 금액 :</ion-label>
        <ion-input
          v-model="data.grade1_price"
          readonly="true" /> 
      </ion-item>
    </ion-list>
  
    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="handlCollectionComplete(data)">
      수거 완료하기
    </vp-button>
  </vp-base-layout>
</template>
  
<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonInput,
  IonListHeader,
  IonItem,
  IonList,
  IonLabel,
  useIonRouter,
  toastController
} from '@ionic/vue'
import { ref, computed, defineComponent, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { updateReceipt } from '@/api/dealer/Receipt.js'
import isEmpty from 'lodash/isEmpty'
import { decrypt } from '@/utils/jsencrypt'
const dayjs = inject('$dayjs')
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const store = useStore()
const ionRouter = useIonRouter()
const fixed = ref(true)
const decryptAcc = ref()

const handlCollectionComplete = async (item) => {
  item.order_state_code = '2'
  item.take_date = dayjs().format('YYYY-MM-DD hh:mm:ss')
  const res = await updateReceipt(item)
  if(res.code == '200'){
    ionRouter.push('/deposit')
  } else {
    const toast = await toastController.create({
    message: '오류가 발생하였습니다.',
    buttons: ['확인']
    })
    await toast.present()
  }  
}
    
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
const data = computed(() => store.state.Receipt)
if(!isEmpty(data.value.acc_num)){
  decryptAcc.value = decrypt(data.value.acc_num)
} else {
  decryptAcc.value = data.value.acc_num
}
const device = data.value.series + ' ' + (isEmpty(data.value.volumn) ? '' : data.value.volumn)
</script>
import request from '@/utils/request'
const BACK_API = '/back/api'
const USER_PATH = `${BACK_API}`
const ADMIN_PATH = `${BACK_API}/admin`

//기기정보 조회
const listData = (query) => {
  return request({
    url: `${USER_PATH}/purchase/meta/`,
    method: 'get',
    params: query
  })
}

//수거 완료
const updateReceipt = (params) => {
  return request({
    url: `${USER_PATH}/purchase/order/${params.id}/`,
    method: 'put',
    data: params
  })
}

//신청자 조회
const getPurchaseOrder = (id) => {
  return request({
    url: `${USER_PATH}/purchase/order/${id}/`,
    method: 'get'
  })
}

//취소하기
const delPurchaseCancel = (params) => {
  return request({
    url: `${USER_PATH}/purchase/order/${params.id}/`,
    method: 'delete',
    data: params
  })
}

//확인하기
const editAcStateCode = (params) => {
  return request({
    url: `${USER_PATH}/purchase/order/${params.id}/`,
    method: 'put',
    data: params
  })
}

//접수상태코드 조회
const getOrderStateYn = () => {
  return request({
    url: `${ADMIN_PATH}/system/dict/get/type/order_state/?status=1`,
    method: 'get'
  })
}

export {
  listData,
  updateReceipt,
  getPurchaseOrder,
  delPurchaseCancel,
  editAcStateCode,
  getOrderStateYn
}